import * as Sentry from '@sentry/remix';

export function registerClientErrorLogging(
  SENTRY_DSN: string,
  tracingIntegrations: any
) {
  if (SENTRY_DSN) {
    Sentry.init({
      dsn: SENTRY_DSN,
      tracesSampleRate: 1,

      integrations: [
        Sentry.browserTracingIntegration(tracingIntegrations),
        Sentry.replayIntegration({
          maskAllText: true,
          blockAllMedia: true
        })
      ],

      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1
    });
  }
}

export function registerServerErrorLogging(SENTRY_DSN?: string) {
  if (!!SENTRY_DSN) {
    Sentry.init({
      dsn: SENTRY_DSN,
      tracesSampleRate: 1,
      autoInstrumentRemix: true
    });
  }
}
